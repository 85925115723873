import * as vars from '../vars.json'
export function printSaleBilling (factura, profile, imbB64, nameDoc) {
  return new Promise((resolve) => {
    var pdfMake = require('pdfmake/build/pdfmake.js')
    if (pdfMake.vfs === undefined) {
      var pdfFonts = require('pdfmake/build/vfs_fonts.js')
      pdfMake.vfs = pdfFonts.pdfMake.vfs
    }
    const productTable = []
    productTable.push(
      [
        {
          text: 'Cantidad',
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'Descripción del Producto',
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'Precio Unitario',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'Total',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
      ],
    )
    for (let i = 0; i < factura.lstProductos.length; i++) {
      const productArr = [
        {
          text: factura.lstProductos[i].cantidad,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          text: factura.lstProductos[i].producto.name,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          border: [false, false, false, true],
          text: '$' + factura.lstProductos[i].valorUnitario,
          fillColor: '#f5f5f5',
          alignment: 'right',
          margin: [0, 5, 0, 5],
        },
        {
          border: [false, false, false, true],
          text: '$' + factura.lstProductos[i].total,
          fillColor: '#f5f5f5',
          alignment: 'right',
          margin: [0, 5, 0, 5],
        },
      ]
      productTable.push(productArr)
    }
    const pdfFormat = {
      footer:
        {
          text: 'Facturación: J2RTech S.A.S Comunicarse a los siguientes contactos: \n Teléfono: 0988511884 - Correo: j2rtechsas@gmail.com \n Dirección: Yanahurco 4-91 y Río Upano',
          style: 'notesText',
        },
      content: [
        {
          columns: [
            {
              image:
              imbB64,
              width: 150,
            },
            [
              {
                text: nameDoc + '',
                color: '#333333',
                width: '*',
                fontSize: 20,
                bold: true,
                alignment: 'right',
                margin: [0, 0, 0, 15],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: nameDoc + ' No.',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: factura.numsecuencial === undefined ? '' : factura.numsecuencial,
                        bold: true,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Número de autorización:',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: 'No emitido',
                        bold: true,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Fecha de emisión',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: profile.create === undefined ? '' : profile.create,
                        bold: true,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  // {
                  //   columns: [
                  //     {
                  //       text: 'Fecha de Autorización: ',
                  //       color: '#aaaaab',
                  //       bold: true,
                  //       width: '*',
                  //       fontSize: 10,
                  //       alignment: 'right',
                  //     },
                  //     {
                  //       text: '26 Mayo 2021',
                  //       bold: true,
                  //       color: '#333333',
                  //       fontSize: 10,
                  //       alignment: 'right',
                  //       width: 100,
                  //     },
                  //   ],
                  // },
                  {
                    columns: [
                      {
                        text: 'Estado:',
                        color: '#aaaaab',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        width: '*',
                      },
                      {
                        text: 'No emitido',
                        bold: true,
                        fontSize: 14,
                        alignment: 'right',
                        color: 'red',
                        width: 100,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        {
          columns: [
            {
              text: 'Datos del Emisor',
              color: '#aaaaab',
              bold: true,
              fontSize: 20,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            {
              text: 'Datos del Proveedor',
              color: '#aaaaab',
              bold: true,
              fontSize: 20,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
          ],
        },
        {
          columns: [
            {
              text: profile.razonSocial === undefined ? 'Razón Social: ' : 'Razón Social: ' + profile.razonSocial,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !factura.provider.name ? 'Razón Social: ' : 'Razón Social: ' + factura.provider.name,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.rucEstablecimieto === undefined ? 'Identificación: ' : 'Identificación: ' + profile.rucEstablecimieto,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !factura.provider.ruc ? 'Identificación: ' : 'Identificación: ' + factura.provider.ruc,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.direccionEstablecimiento === undefined ? 'Dirección: ' : 'Dirección: ' + profile.direccionEstablecimiento,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !factura.provider.address ? 'Dirección: ' : 'Dirección: ' + factura.provider.address,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.telefonoEstablecimiento === undefined ? 'Teléfono: ' : 'Teléfono: ' + profile.telefonoEstablecimiento,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !factura.provider.phone ? 'Teléfono: ' : 'Teléfono: ' + factura.provider.phone,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.correoEstablecimiento === undefined ? 'Email: ' : 'Email: ' + profile.correoEstablecimiento,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !factura.provider.mail ? 'Email: ' : 'Email: ' + factura.provider.mail,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },

        '\n\n',
        {
          width: '100%',
          alignment: 'center',
          text: 'Descripción',
          bold: true,
          margin: [0, 10, 0, 10],
          fontSize: 15,
        },
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
            hLineColor: function (i, node) {
              if (i === 1 || i === 0) {
                return '#bfdde8'
              }
              return '#eaeaea'
            },
            vLineColor: function (i, node) {
              return '#eaeaea'
            },
            hLineStyle: function (i, node) {
              // if (i === 0 || i === node.table.body.length) {
              return null
              // }
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 2
            },
            paddingBottom: function (i, node) {
              return 2
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return '#fff'
            },
          },
          table: {
            headerRows: 1,
            widths: ['15%', '55%', '15%', '15%'],
            body: productTable,
          },
        },
        '\n',
        '\n\n',
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
            hLineColor: function (i, node) {
              return '#eaeaea'
            },
            vLineColor: function (i, node) {
              return '#eaeaea'
            },
            hLineStyle: function (i, node) {
              // if (i === 0 || i === node.table.body.length) {
              return null
              // }
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 3
            },
            paddingBottom: function (i, node) {
              return 3
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return '#fff'
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Subtotal de pago:',
                  border: [false, true, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  border: [false, true, false, true],
                  text: '$' + (factura.subtotal ? factura.subtotal : '0.00'),
                  alignment: 'right',
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'IVA ' + vars.validIva + '%',
                  border: [false, false, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  text: '$' + (factura.iva12 ? factura.iva12 : '0.00'),
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'TOTAL DESCUENTO',
                  border: [false, false, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  text: '$' + (factura.totdcto ? factura.totdcto : '0.00'),
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'Saldo',
                  border: [false, false, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  text: '$' + (factura.saldo ? factura.saldo : '0.00'),
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'Monto Total',
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 5],
                },
                {
                  text: 'USD $' + (factura.total ? factura.total : '0.00'),
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
        },
      ],
      styles: {
        notesTitle: {
          fontSize: 8,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 8,
          margin: [10, 10, 10, 10],
        },
      },
      defaultStyle: {
        columnGap: 20,
        // font: 'Quicksand',
      },
    }
    var docDefinition = pdfFormat
    pdfMake.createPdf(docDefinition).download(factura.numsecuencial === undefined ? nameDoc + '.pdf' : nameDoc + '-' + factura.numsecuencial + '.pdf')
    resolve(true)
  })
}
